import React, { useEffect, useState } from 'react';
import ProductCard from '../ProductCard/ProductCard';
import { useNavigate } from 'react-router-dom';
import './product.css';
import ProductCarousel from '../ProductCarousel';


function ProductSection() {


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        // Limpeza na desmontagem
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const navigate = useNavigate();

    const [data, setData] = useState(null);
    const [listOfCategories, setListOfCategories] = useState(null);

    useEffect(() => {
        const fetchListofCategories = async () => {
            try {
                const response = await fetch('https://reter.feelingdigital.com.br/public/api/categorias');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const mydata = await response.json();
                const filteredData = mydata.categorias.filter(item => item.destaque === 1);
                setListOfCategories(filteredData);
            } catch (error) {
            }
        };

        fetchListofCategories();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://reter.feelingdigital.com.br/public/api/produtos');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const mydata = await response.json();

                const filteredData = mydata.produtos.filter(item => item.exibir === 1);
                setData(filteredData);
            } catch (error) {
            }
        };

        fetchData();
    }, []);

    return (
        <div className='productSectionContainer'>
            {listOfCategories && listOfCategories.map((category, idx) => {
                return (
                    <div className='productCategoryContainer' key={idx}>
                        <div className='productAreaContainer'>
                            <div className='productTitleContainer'>
                                <label className='productTitleLabel'>
                                    {category.nome.toUpperCase()}
                                </label>
                            </div>
                            <div className='productDescription'>
                                <label className='productDescriptionLabel'>
                                    {category.descricao}
                                </label>
                            </div>
                        </div>
                        <div className='productMainCardContainer'>
                            {
                                windowWidth > 768 ?
                                data && data.filter(product => product.categoria_id === category.id).slice(0, 3).map((it, index) => <ProductCard key={index} productData={it} />) :
                                data && <ProductCarousel listOfProducts={data.filter(product => product.categoria_id === category.id)} />
                            }
                        </div>
                        <div className='productButtonContainer'>
                            <button className='productButton' onClick={() => navigate(`/${category.slug}`)}>
                                VER MAIS
                            </button>
                        </div>
                    </div>
                )
            }
            )}
        </div>
    );
};

export default ProductSection;