import React, { useState } from 'react';
import './whatsAppForm.css';


const WhatsAppForm = () => {

    const [name, setName] = useState('');
    const [city, setCity] = useState('');

    const handleClick = () => {
        window.location.href = `https://api.whatsapp.com/send?phone=5516991644343&text=Contato+via+portal+Reter+Website Nome: ${name} Cidade:${city}`;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('form submitted');
        handleClick();
    }

    return (
        <div className='whatsAppContainer' id='callus'>
            <div className='whatsAppMainContainer'>
                <div className='titleWhatsAppcontainer'>
                    <div className="whatsAppLeftSideContainer">
                        <label className='titleWhatsAppLabel'>
                            ESTAMOS NO WHATSAPP!
                        </label>
                    </div>
                    <div className='whatsAppRightSideContainer'>
                        <img src='WhatAppFormIcon.png' alt="whatsAppIcon" className='whatsAppIcon' />
                    </div>
                </div>
                <div className='whatsAppFormContainer'>
                    <form onSubmit={handleSubmit} className='WhatsAppForm'>
                        <input
                            type="text"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            id='whatAppInputName'
                            placeholder="Seu nome:"
                            className='whatAppInputName' 
                            />
                        <input
                            type="text"
                            value={city}
                            onChange={e => setCity(e.target.value)}
                            id='whatAppInputCity'
                            placeholder="Sua Cidade:"
                            className='whatAppInputCity' 
                            />
                        <button type="submit" className='whatAppFormButton'>
                            CHAMAR
                        </button>
                    </form>
                </div>

            </div>
        </div>
    );
};
export default WhatsAppForm;