import React from 'react';
import Logo from '../TopLogo/Logo';
import './productsBanner.css';


const ProductsBanner = () => {
    return (
        <div>
            <div className="productsBannerMainContainer">
                <Logo />
            </div>
        </div>
    );
};
export default ProductsBanner;