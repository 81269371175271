import React from 'react';
import './support.css';


const Support = () => {
    const handleClick = () => {
        window.location.href = 'https://api.whatsapp.com/send?phone=5516991644343';
      }

    return (
        <div className='supportMainContainer'>
            <div className="supportContainerArea">
                <div className="supportImageContainer">
                    <img src="AssistenciaTecnica.png" alt="Support" className='supportImg' />
                </div>
                <div className='supportDescriptionContainer'>
                    <label className='supportTitle'>
                        ASSISTÊNCIA TÉCNICA
                    </label>
                    <label className='supportDescription'>
                        A Reter possui profissionais especializados para a execução de serviços como a reforma, manutenção e restauração de válvulas, cilindros, equipamentos hidráulicos e pneumáticos.
                    </label>
                    <button className='supportButton' onClick={handleClick}>
                        SAIBA MAIS
                    </button>
                </div>
            </div>

        </div>
    );
};
export default Support;