import React from 'react';
import BannerCarousel from '../../components/HomeBanner/Banner'
import Footer from '../../components/Footer/Footer';
import ProductSection from '../../components/Product/Product';
import SideMenu from '../../components/SideMenu/SideMenu';
import Support from '../../components/Support/Support';
import WhatsAppForm from '../../components/WhatsAppForm/WhatsAppForm';
import './index.css';

function Home() {
    return (
        <div className="Home">
            <div className="headerContainer">
                <BannerCarousel />
            </div>
            <div className="homeMainContainer">
                <div className="bodyContainer">
                    <div className="leftSideContainer">
                        <SideMenu />
                        <WhatsAppForm />
                    </div>
                    <div className="rightSideContainer">
                        <ProductSection />
                    </div>
                </div>
                <Support />
            </div>
            <Footer />
        </div>
    );
};
export default Home;