import React from 'react';
import { useNavigate } from 'react-router-dom';
import './logo.css';


const Logo = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/home');
  }

  return <div className='logoContainer'>
    <img src="logo.png" alt="Reter Logo" className='logoImg' onClick={handleClick}/>
  </div>;
}

export default Logo;