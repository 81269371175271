import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import './index.css';


function ProductCarousel(props) {
    const carousel = useRef();
    const [width, setWidth] = useState(0);

    useEffect(() => {
        setWidth(carousel.current?.scrollWidth - carousel.current?.offsetWidth);
    }, [carousel.current?.scrollWidth]);
    return (
        <div>
            <motion.div className='productCarouselTitleContainer' ref={carousel} whileTap={{ cursor: "grabbing" }}>
                <motion.div
                    className='productCarouselMainContainer'
                    drag="x"
                    dragConstraints={{ right: 0, left: -width}}
                    initial={{x: 300}}
                    animate={{x: 0}}
                    transition={{duration: 0.9}}>
                    {props.listOfProducts.map((product, idx) => {
                        // console.log(product);
                        return (
                            <motion.div className='productCarouselContainer' key={idx}>
                                <motion.div className='productItem'>
                                    <img
                                        src={`https://reter.feelingdigital.com.br/public/img/produtos/${product.imagem}`}
                                        alt={product.slug || "product"}
                                        className='productImg'
                                    />
                                </motion.div>
                                <motion.div className='productNameContainer'>
                                    <motion.label className='productNameLabel'>
                                        {product.nome.toUpperCase()}
                                    </motion.label>
                                </motion.div>
                            </motion.div>
                        );
                    })}
                </motion.div>
            </motion.div>
        </div>
    );
}

export default ProductCarousel;