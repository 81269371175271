import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';


import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './banner.css';
import Logo from '../TopLogo/Logo';


function BannerCarousel() {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://reter.feelingdigital.com.br/public/api/banners');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const mydata = await response.json();
        const filteredData = mydata.banners.filter(item => item.ativo === 1);
        setData(filteredData);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, []);


  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: false,
  };


  return (
    <div className='bannerContainer'>
      <img src='./degrade.png' className='degradeContainer'>
      </img>
      <div className='waveContainer'>
      </div>
      <Logo />
      <Slider {...settings} className='sliderContainer'>
        {data && data.map((banner, index) => (
          <div key={index} className='carrouselContainer'>
            <img src={`https://reter.feelingdigital.com.br/public/img/banners/${banner.imagem}`} alt={`Banner ${index + 1}`} className='carrousselImg' />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BannerCarousel;
