import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./sideMenu.css";


const SideMenu = () => {

    const navigate = useNavigate();

    const [listOfCategories, setListOfCategories] = useState(null);
    // const [slug, setSlug] = useState(null);

    useEffect(() => {
        const fetchListofCategories = async () => {
            try {
                const response = await fetch('https://reter.feelingdigital.com.br/public/api/categorias');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const mydata = await response.json();
                setListOfCategories(mydata.categorias);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchListofCategories();
    }, []);

    // const handleClick = (selected) => {
    //     setSlug(selected);
    //     navigate(`/${slug}`);
    //   }

    return (
        <div className="sideMenuContainer">
            <div className="sideMenuSubContainer">
                <label className="sideMenuSubTitle">O que você</label>
                <label className="sideMenuTitle">PROCURA?</label>
                {
                    listOfCategories && listOfCategories.map((category, index) => {
                        return (
                            <nav>
                                <a href={`/${category.slug}`} key={index} className="sideMenuOption">{category.nome.toUpperCase()}</a>
                            </nav>
                        );
                    })
                }
            </div>
        </div>
    );
};
export default SideMenu;