import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import ProductsBanner from "../../components/ProductsBanner/ProductsBanner";
import Footer from "../../components/Footer/Footer";
import SideMenu from '../../components/SideMenu/SideMenu';
import WhatsAppForm from '../../components/WhatsAppForm/WhatsAppForm';
import ProductCard from '../../components/ProductCard/ProductCard';
import './productsPage.css';


function ProductsPage() {

    const [data, setData] = useState(null);
    const { slug } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://reter.feelingdigital.com.br/public/api/produtos');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const mydata = await response.json();
                const filteredData = mydata.produtos.filter(item => item.categoria.slug === slug);
                console.log(filteredData);
                setData(filteredData);
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="productsMainContainer">
            <div>
                <ProductsBanner />
            </div>
            <div>
                <div className="productMainContainer">
                    <div className="productsleftSideContainer">
                        <SideMenu />
                        <WhatsAppForm />
                    </div>
                    <div className="productsrightSideContainer">
                        <div className='productAreaContainer'>
                            <div className='productTitleContainer'>
                                <label className='productTitleLabel'>
                                    {data && data[0].categoria.nome.toUpperCase()}
                                </label>
                            </div>
                            <div className='productDescription'>
                                <label className='productDescriptionLabel'>
                                    {data && data[0].categoria.descricao}
                                </label>
                            </div>
                        </div>
                        <div className="productMainCardContainer">
                            {data && data.map((item, index) => <ProductCard key={index} productData={item} />)}
                        </div>
                    </div>
                </div>
                <div className="productsfooterContainer">
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default ProductsPage;