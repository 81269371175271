import React, { useState } from "react";
import "./productCard.css";


const ProductCard = (props) => {

    const [isOpen, setIsOpen] = useState(false);


    function openModal() {
        setIsOpen(true);
    }

    return (
        <div className="productCardContainer">
            <div className="cardAreaContainer">
                <div className="productImgContainer">
                    <img
                        src={`https://reter.feelingdigital.com.br/public/img/produtos/${props.productData.imagem}`}
                        alt={props.productData.slug || "product"}
                        className="productImg"
                        onClick={openModal}
                    />
                    {isOpen && (
                        <div
                            style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: 2,
                            }}
                            onClick={() => setIsOpen(false)}
                        >
                            <img src={`https://reter.feelingdigital.com.br/public/img/produtos/${props.productData.imagem}`} style={{ width: '600px', height: '600px' }} alt={props.productData.slug || "product"}/>
                            <h1 className="productAfterClickTitle">{props.productData.nome}</h1>
                        </div>
                    )}

                </div>
                <div className="productNameContainer">
                    <label className="productNameLabel">
                        {props.productData.nome.toUpperCase()}
                    </label>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;