import React from 'react';
import './footer.css';
import { ReactComponent as LinkedInIcon } from '../../assets/LinkedIn.svg';
import { ReactComponent as WhatAppIcon } from '../../assets/WhatsApp.svg';
import { ReactComponent as InstagramIcon } from '../../assets/Instagram.svg';
import { ReactComponent as FaceBookIcon } from '../../assets/Facebook.svg';


const Footer = () => {
    return (
        <div className="footerContainer">
            <div className="footerMainContaner">
            
                <div className="footerLeftSideContainter">
                    <div>
                        <img src="Footer.png" alt="Footer Image" className='footerLeftSideImg' />
                    </div>
                    <label className='footerLeftSideLabel'>
                        Reter Verdações e Serviços  © 2024 - Política de Privacidade
                        Desenvolvimento Nativo: Agência Feeling Digital
                    </label>
                </div>
                <div className="footerRightSideContainer">
                    <div className="footerRightSideTopContainer" id='whoweare'>
                        <label>
                            A Reter destaca-se por sua excelência no atendimento a diversos setores, abrangendo áreas como Sucroalcooleiro, Laticínios, Metalúrgicos, Farmacêuticos, Alimentício, Calçadista e Siderúrgico.
                            Oferecemos uma ampla variedade de produtos de alta qualidade, além de especialização no desenvolvimento de peças sob medida, seguindo desenhos ou amostras fornecidas pelos clientes.
                            <br />
                            <br />
                            Nosso compromisso vai além da oferta de produtos, incluindo serviços especializados, assistência técnica e consultoria, proporcionando soluções completas e personalizadas para as necessidades específicas de cada cliente.
                        </label>
                    </div>
                    <div className="footerRightSideBottomContainer">
                        <div className="footerRightSideBottomLeftSideContainer">
                            <label className='footerLeftSideTitle'>
                                RETER VEDAÇÕES E SERVIÇOS
                            </label>
                            <label className='footerleftsideLocationLabel'>
                                CNPJ: 12.933.858/0001-98
                                <br />
                                <br />
                                Rua Venceslau Braz, 331 - Jd. Paulistano
                                <br />
                                Ribeirão Preto - SP | 14090-342
                            </label>
                        </div>
                        <div className="footerRightSideBottomCenterContainer">
                            <div className='contactArea'>
                                (16) 3961-1942 | (16) 99282-3424
                                <br />
                                reter@reter.com.br
                            </div>
                            <div className="socialIconArea">
                                <LinkedInIcon className='socialIcons' />
                                <WhatAppIcon className='socialIcons' />
                                <InstagramIcon className='socialIcons' />
                                <FaceBookIcon className='socialIcons' />
                            </div>
                        </div>
                        <div className="footerRightSideBottomRightSideContainer">
                            <nav className='nav-container'>
                                <a href="/" className='nav-button'>Home</a>
                            </nav>
                            <nav className='nav-container'>
                                <a href="#whoweare" className='nav-button'>Quem somos</a>
                            </nav>
                            <nav className='nav-container'>
                                <a href="#callus" className='nav-button'>Fale conosco</a>
                            </nav>
                        </div>
                    </div>
                </div>
            <div className='footerBackground'></div>
            </div>
        </div>
    );
};
export default Footer;